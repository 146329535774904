<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list1') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.name" :label="$t('web_setting.input1')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.line_id" label="Line ID" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.line_url" label="Line Url" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.phone" :label="$t('web_setting.input2')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.logo" label="Logo" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <input type="file" accept="image/*" @change="uploadFile('file')" placeholder="อัพโหลดรูปโลโก้" ref="file"
                style="width: 200px; display: none;">
              <v-btn text color="primary" for="upload" class="btn-custom btn-upload-logo" @click="$refs.file.click()">
                {{ $t('upload') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.title" label="Web Title" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.description" label="Web description" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.keyword" label="Web keyword" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.clear_turn" label="Clear Turn" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.min_withdraw" :label="$t('web_setting.input3')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.min_deposit_auto" :label="$t('web_setting.input4')" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="form.enable_line_notify" :items="selfReturnItem" :label="$t('web_setting.input5')"
                outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="8" v-if="form.enable_line_notify == 1">
              <v-textarea v-model="form.line_notify_token" label="Line notify token API" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <!-- <v-card-actions>
          <v-row>
            <v-col cols="4" xs="12">
              <v-select  v-model="form.enable_line_notify_log" :items="selfReturnItem" label="สถานะ Line notify Log API" outlined dense />
            </v-col>
            <v-col cols="8" xs="12">
              <v-textarea v-model="form.line_notify_token_log" label="Line notify token Log API" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions> -->
        <!-- <v-card-actions>
          <v-row>
            <v-col cols="4" xs="12">
              <v-text-field v-model="form.name"  label="SMS API Username" outlined dense />
            </v-col>
            <v-col cols="4" xs="12">
              <v-text-field v-model="form.name" label="SMS API Password" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions> -->
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list2') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="form.register_member" value="value" :label="$t('web_setting.input6')"
                :items="autoCreateUserItem" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" v-if="form.register_member === 'after_first_deposit'">
              <v-text-field v-model="form.min_deposit_register_member" :label="$t('web_setting.input6_2')" outlined
                dense />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list3') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="form.alert_sound" value="value" :label="$t('web_setting.input7')" :items="alertSoundItem"
                outlined dense @change="playSample" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list4') }}</v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="6" class="d-flex">
              <v-text-field v-model="otpAdmin" :label="$t('web_setting.head_list8')" :rules="rulesOtpAdmin" outlined
                dense />
              <v-btn text color="primary" class="btn-custom" :loading="loading_otp" @click="updateOtpAdmin()">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list5') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="affiliateForm['1'].type" :items="bonusRefer" item-text="text"
                :label="$t('web_setting.input6')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="affiliateForm['1'].turn" :label="$t('web_setting.input9')" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4" v-for="(i, n) in affiliateForm['1'].data" :key="n">
              <v-text-field v-model="affiliateForm['1'].data[n].percent"
                :label="`${$t('web_setting.input10')} (${i.type_game})`" outlined dense />
            </v-col>
            <!-- <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Percent จากยอดเล่น (CASINO)" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Percent จากยอดเล่น (SLOT)" outlined dense />
            </v-col> -->
          </v-row>
        </v-card-actions>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="affiliateForm['2'].use" :label="$t('web_setting.input13')" value="value"
                :items="statusItem" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" v-if="affiliateForm['2'].use">
              <v-select v-model="affiliateForm['2'].type" :items="bonusRefer" item-text="text"
                :label="$t('web_setting.input6')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" v-if="affiliateForm['2'].use">
              <v-text-field v-model="affiliateForm['2'].turn" :label="$t('web_setting.input9')" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions v-if="affiliateForm['2'].use">
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4" v-for="(i, n) in affiliateForm['2'].data" :key="n">
              <v-text-field v-model="affiliateForm['2'].data[n].percent"
                :label="`${$t('web_setting.input10')} (${i.type_game})`" outlined dense />
            </v-col>
            <!-- <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Percent จากยอดเล่น (CASINO)" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Percent จากยอดเล่น (SLOT)" outlined dense />
            </v-col> -->
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list6') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="cashbackForm.enable" :label="$t('web_setting.input14')" value="value"
                :items="selfReturnItem" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="cashbackForm.percent" :label="$t('web_setting.input15')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="cashbackForm.multiply_turnover" :label="$t('web_setting.input9')" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="cashbackForm.max_credit" :label="$t('web_setting.input16')" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list7') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="banner1" type="text" placeholder="รูปภาพแบนเนอร์ 1" readonly>
                <button class="clear-btn" v-if="banner1" @click="clearValue('banner1')">X</button>
                <input type="file" accept="image/*" @change="uploadFile('fileBarner1')" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileBarner1" style="width: 200px; display: none;">
                <v-btn text color="primary" for="upload" class="btn-custom" @click="$refs.fileBarner1.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="banner2" type="text" placeholder="รูปภาพแบนเนอร์ 2" readonly>
                <button class="clear-btn" v-if="banner2" @click="clearValue('banner2')">X</button>
                <input type="file" @change="uploadFile('fileBarner2')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileBarner2" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileBarner2.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="banner3" type="text" placeholder="รูปภาพแบนเนอร์ 3" readonly>
                <button class="clear-btn" v-if="banner3" @click="clearValue('banner3')">X</button>
                <input type="file" @change="uploadFile('fileBarner3')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileBarner3" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileBarner3.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="banner4" type="text" placeholder="รูปภาพแบนเนอร์ 4" readonly>
                <button class="clear-btn" v-if="banner4" @click="clearValue('banner4')">X</button>
                <input type="file" @change="uploadFile('fileBarner4')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileBarner4" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileBarner4.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="banner5" type="text" placeholder="รูปภาพแบนเนอร์ 5" readonly>
                <button class="clear-btn" v-if="banner5" @click="clearValue('banner5')">X</button>
                <input type="file" @change="uploadFile('fileBarner5')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileBarner5" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileBarner5.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list8') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="bgRegister1" type="text" placeholder="รูปภาพสำหรับคอมพิวเตอร์"
                  readonly>
                <button class="clear-btn" v-if="bgRegister1" @click="clearValue('bgRegister1')">X</button>
                <input type="file" @change="uploadFile('fileBgRegisterPC')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileBgRegisterPC" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileBgRegisterPC.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="bgRegister2" type="text" placeholder="รูปภาพสำหรับโทรศัพท์"
                  readonly>
                <button v-if="bgRegister2" class="clear-btn" @click="clearValue('bgRegister2')">X</button>
                <input type="file" @change="uploadFile('fileBgRegisterMobile')" accept="image/*"
                  placeholder="อัพโหลดรูปโลโก้" ref="fileBgRegisterMobile" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileBgRegisterMobile.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list9') }}</v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="bgContact" type="text" placeholder="รูปภาพติดต่อเรา" readonly>
                <button class="clear-btn" v-if="bgContact" @click="clearValue('bgContact')">X</button>
                <input type="file" @change="uploadFile('fileContact')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileContact" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileContact.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list12') }}</v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <div class="upload-container">
                <input class="upload-banner" v-model="bgMenuBar" type="text" :placeholder="$t('web_setting.head_list12')" readonly>
                <button class="clear-btn" v-if="bgMenuBar" @click="clearValue('bgMenuBar')">X</button>
                <input type="file" @change="uploadFile('fileMenuBar')" accept="image/*" placeholder="อัพโหลดรูปโลโก้"
                  ref="fileMenuBar" style="width: 200px; display: none;">
                <v-btn text color="primary" class="btn-custom" @click="$refs.fileMenuBar.click()">
                  {{ $t('upload') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';
import * as Minio from 'minio';
export default {
  props: {
    info: {
      type: Object,
      require: true,
    },
    affiliateInfo: {
      type: Object,
      require: () => { },
    },
    cashbackInfo: {
      type: Object,
      require: true,
    }
  },
  data() {
    return {
      affiliateSecondStatus: true,
      loading_otp: false,
      statusItem: [
        {
          text: this.$t('web_setting.status1'),
          value: true,
        },
        {
          text: this.$t('web_setting.status2'),
          value: false,
        },
      ],
      autoCreateUserItem: [
        {
          text: this.$t('web_setting.user_format1'),
          value: 'after_register',
        },
        {
          text: this.$t('web_setting.user_format2'),
          value: 'after_first_deposit',
        },
      ],
      alertSoundItem: [
        {
          text: this.$t('web_setting.sound') + ' 1',
          value: 'alert_sound1.mp3',
        },
        {
          text: this.$t('web_setting.sound') + ' 2',
          value: 'alert_sound2.mp3',
        },
        {
          text: this.$t('web_setting.sound') + ' 3',
          value: 'alert_sound3.mp3',
        },
        {
          text: this.$t('web_setting.sound') + ' 4',
          value: 'alert_sound4.mp3',
        },
        {
          text: this.$t('web_setting.sound') + ' 5',
          value: 'alert_sound5.mp3',
        },
      ],
      bonusRefer: [
        {
          text: this.$t('web_setting.bonus_refer1'),
          value: 'turnover',
        },
        {
          text: this.$t('web_setting.bonus_refer2'),
          value: 'turnwinloss',
        },
      ],
      selfReturnItem: [
        {
          text: this.$t('web_setting.status2'),
          value: 1,
        },
        {
          text: this.$t('web_setting.status1'),
          value: 0,
        },
      ],
      logoImage: null,
      banner1: '',
      banner2: '',
      banner3: '',
      banner4: '',
      banner5: '',
      bgRegister1: '',
      bgRegister2: '',
      bgContact: '',
      bgMenuBar: '',
      otpAdmin: '',
      rulesOtpAdmin: [v => v.length === 0 || this.$t('alert_list.list13')],
      adminRole: '',
      adminName: ''
    }
  },
  async created() {
    const localAuth = JSON.parse(localStorage.getItem('vuex'))
    this.adminRole = localAuth?.auth.userinfo.role
    this.adminName = localAuth?.auth.userinfo.name
  },
  methods: {
    playSample(soundfile) {
      var soundurl = require(`@/assets/sounds/${soundfile}`)
      var audio = new Audio(soundurl)
      audio.play()
    },

    async uploadFile(type) {
      this.$swal.fire({
        html: this.$t('processing'),
        timerProgressBar: true,
        didOpen: () => { this.$swal.showLoading() }
      })

      let dataFile
      switch (type) {
        case 'file':
          dataFile = this.$refs.file.files[0]
          break;
        case 'fileBarner1':
          dataFile = this.$refs.fileBarner1.files[0]
          break;
        case 'fileBarner2':
          dataFile = this.$refs.fileBarner2.files[0]
          break;
        case 'fileBarner3':
          dataFile = this.$refs.fileBarner3.files[0]
          break;
        case 'fileBarner4':
          dataFile = this.$refs.fileBarner4.files[0]
          break;
        case 'fileBarner5':
          dataFile = this.$refs.fileBarner5.files[0]
          break;
        case 'fileBgRegisterPC':
          dataFile = this.$refs.fileBgRegisterPC.files[0]
          break;
        case 'fileBgRegisterMobile':
          dataFile = this.$refs.fileBgRegisterMobile.files[0]
          break;
        case 'fileContact':
          dataFile = this.$refs.fileContact.files[0]
          break;
        case 'fileMenuBar':
          dataFile = this.$refs.fileMenuBar.files[0]
          break;
        default:
          break;
      }

      var bodyFormData = new FormData();
      bodyFormData.append('name', type);
      bodyFormData.append('file', dataFile);
      let dataRes

      dataRes = await this.$store.dispatch('putSettingUpload', bodyFormData)
      // console.log(dataRes, dataRes.data.file_url);

      if (dataRes) {
        this.$swal.fire(this.$t('upload_complete'), '', 'success')
        if (type === 'file') {
          this.form.logo = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBarner1') {
          this.banner1 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.banner1 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBarner2') {
          this.banner2 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.banner2 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBarner3') {
          this.banner3 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.banner3 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBarner4') {
          this.banner4 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.banner4 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBarner5') {
          this.banner5 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.banner5 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBgRegisterPC') {
          this.bgRegister1 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.bgRegister1 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileBgRegisterMobile') {
          this.bgRegister2 = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.bgRegister2 = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileContact') {
          this.bgContact = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.bgContact = dataRes.data.file_url ? dataRes.data.file_url : ''
        } else if (type === 'fileMenuBar') {
          this.bgMenuBar = dataRes.data.file_url ? dataRes.data.file_url : ''
          this.form.bgMenuBar = dataRes.data.file_url ? dataRes.data.file_url : ''
        }
      } else {
        this.$swal.fire(this.$t('upload_fail'), '', 'error')
      }
    },

    submitFile() {
      const formData = new FormData();
      formData.append('file', this.logoImage);
      const headers = { 'Content-Type': 'multipart/form-data' };
      // console.log(formData);
    },

    clearValue(val) {
      switch (val) {
        case 'banner1':
          this.banner1 = ''
          this.info.banner1 = ''
          break;
        case 'banner2':
          this.banner2 = ''
          this.info.banner2 = ''
          break;
        case 'banner3':
          this.banner3 = ''
          this.info.banner3 = ''
          break;
        case 'banner4':
          this.banner4 = ''
          this.info.banner4 = ''
          break;
        case 'banner5':
          this.banner5 = ''
          this.info.banner5 = ''
          break;
        case 'bgRegister1':
          this.bgRegister1 = ''
          this.info.bgRegister1 = ''
          break;
        case 'bgRegister2':
          this.bgRegister2 = ''
          this.info.bgRegister2 = ''
          break;
        case 'bgContact':
          this.bgContact = ''
          this.info.bgContact = ''
          break;
        case 'bgMenuBar':
          this.bgMenuBar = ''
          this.info.bgMenuBar = ''
          break;
        default:
          break;
      }
    }
  },

  computed: {
    form: {
      get() {
        // console.log(this.info);
        if (this.info.banner1 !== undefined) {
          this.banner1 = this.info.banner1
          this.banner2 = this.info.banner2
          this.banner3 = this.info.banner3
          this.banner4 = this.info.banner4
          this.banner5 = this.info.banner5
          this.bgRegister1 = this.info.bgRegister1
          this.bgRegister2 = this.info.bgRegister2
          this.bgContact = this.info.bgContact
          this.bgMenuBar = this.info.bgMenuBar
        } else {
          if (this.banner1 = this.info.banners['banner1'] !== undefined) {
            this.banner1 = this.info.banners['banner1']
            this.banner2 = this.info.banners['banner2']
            this.banner3 = this.info.banners['banner3']
            this.banner4 = this.info.banners['banner4']
            this.banner5 = this.info.banners['banner5']
            this.bgRegister1 = this.info.banners['bgRegister1']
            this.bgRegister2 = this.info.banners['bgRegister2']
            this.bgContact = this.info.banners['bgContact']
            this.bgMenuBar = this.info.banners['bgMenuBar']
          }
        }

        return this.info
      },
      set(value) {
        this.$emit('setValue', value)
      },
    },
    affiliateForm: {
      get() {
        return this.affiliateInfo
      },
      set(value) {
        this.$emit('setAffiliate', value)
      },
    },
    cashbackForm: {
      get() {
        return this.cashbackInfo
      },
      set(value) {
        this.$emit('setCashback', value)
      },
    },
    async updateOtpAdmin() {
      this.loading_otp = true

      if (!this.otpAdmin) {
        this.$swal.fire(this.$t('web_setting.otp1'), '', 'error')
      } else if (this.otpAdmin.length < 6) {
        this.$swal.fire(this.$t('web_setting.otp2'), '', 'error')
      } else {
        const data = { data: this.otpAdmin, user: this.adminName ? this.adminName : 'admin' }
        const res = await this.$store.dispatch('putSettingOtpAdmin', data)
        res?.status === 200
          ? (this.$swal.fire(this.$t('web_setting.otp3'), '', 'success'))
          : this.$swal.fire(this.$t('web_setting.otp4'), '', 'error')
      }

      this.loading_otp = false
    }
  },
}
</script>
<style scoped>
.btn-custom {
  background: #7367f0;
  color: white !important;
}

.upload-container {
  width: 100%;
}

.upload-container input {
  height: 39px;
  width: 71%;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(138, 138, 138);
}

.upload-container input::placeholder {
  color: rgb(139, 139, 139);
  font-size: 13px;
}

.upload-banner:hover+.clear-btn {
  display: block !important;
}

.clear-btn {
  display: none;
  height: 25px;
  width: 25px;
  z-index: 5;
  position: absolute;
  border: 1px solid gray;
  border-radius: 50%;
  background: white;
  margin: -33px 0 0 -15px;
}

.clear-btn:hover {
  display: block !important;
}

/* btn-custom */
@media (max-width: 550px) {
  .btn-custom {
    width: 80px !important;
    min-width: 80px !important;
  }

  .btn-upload-logo {
    margin-top: -50px !important;
  }
}
</style>


